@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&family=Manrope:wght@200..800&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

@import url(./cssRoots/root.css);

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

body {
  width: 100%;
  height: 100vh;
  background-color: rgb(var(--block-background));
}

body.modal-show {
  position: relative;
  overflow: hidden;
}

ul, ol {
  list-style-type: none;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
}

input {
  background-color: transparent;
  border: 1px solid var(--greyD6);
  border-radius: 3px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* div[data-rbd-placeholder-context-id] {
  background-color: red !important;
} */

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  color: rgb(var(--text-color));
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  user-select: none;
}

.App {
  height: 100%;
  width: 100%;
}

#root {
  height: 100%;
  width: 100%;
}

@media (max-width: 768px) {
  * {
    font-size: 12px;
  }
}
