.user {
  width: max-content;
}

.userInfo {
  width: 0;
  padding-left: 0;
  transform-origin: left;
  transform: scaleX(0);
  /* transition: .4s; */
}

.userInfo.fullNav {
  padding-left: 16px;
  width: max-content;
  transform: scaleX(1);
}

.name {
  font-weight: 500;
  text-align: left;
}

.email {
  font-size: 10px;
  font-weight: 400;
}

.avatar {
  position: relative;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: var(--greyD9);
}

.initial {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  font-size: 16px;
  font-weight: 700;
}
