.preloaderBox {
  position: fixed;
  left: calc(50% - 35px);
  top: calc(50% - 35px);
  display: flex;
  width: max-content;
  height: max-content;
  align-items: center;
  justify-content: center;
  z-index: 5000;
}

.preloaderBox img {
  width: 70px;
  height: 70px;
}
