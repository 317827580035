.notification {
  position: absolute;
  top: 18px;
  right: 5px;
}

.list {
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  position: absolute;
  top: calc(100% + 20px);
  right: 0;
  width: 460px;
}

.count {
  position: absolute;
  justify-content: center;
  bottom: -4px;
  left: -4px;
  min-width: 16px;
  height: 16px;
  background-color: var(--redCF);
  font-size: 9px;
  line-height: 9px;
  border-radius: 50%;
}

@media (max-width: 576px) {
  .list {
    width: 300px;
  }
}
