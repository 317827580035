.user {
  position: relative;

  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: var(--greyD9);
  cursor: pointer;
  transition: .3s;
}

.userImage {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.user:hover {
  z-index: 20;
  transform: scale(1.1);
}

.toLeft {
  margin-left: -6px;
}

.initial {
  font-size: 12px;
}

.tooltip {
  position: absolute;
  width: max-content;
  left: 50%;
  top: calc(100% - 2px);
  transform: scale(0) translateX(-50%);
  transform-origin: left top;
  font-size: 12px;
  transition: .3s;
}

.user:hover .tooltip{
  transform: scale(1) translateX(-50%);
}

.toTop.user .tooltip {
  left: calc(100% + 2px);
  top: calc(50% + 5px);
  transform: scale(0) translateY(-50%);
  padding: 2px;
  border-radius: 2px;
  background-color: rgb(var(--block-background));
  transform-origin: left center;
}

.toTop.user:hover .tooltip{
  transform: scale(1) translateY(-50%);
}
