.addColumn {
  flex-direction: column;
  gap: 10px;
  min-width: 300px;
  width: 344px;
  padding: 7px 10px 12px;
}

.addColumnBtn {
  align-self: flex-end;
}

@media (max-width: 1400px) {}

@media (max-width: 1200px) {}

@media (max-width: 992px) {}

@media (max-width: 768px) {
  .addColumn {
    width: 310px;
  }

}

@media (max-width: 576px) {}
