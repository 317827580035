:root {
  --white: #FFFFFF;
  --whiteF8: #F8F8F8;
  --whiteFD: #FDFDFD;
  --whiteF4: #F4F4F4;
  --grey78: #787878;
  --greyF1: #F1F1F1;
  --greyE1: #E1E1E1;
  --greyE7: #E7E7E7;
  --greyE6: #E6E6E6;
  --greyE9: #E9E9E9;
  --greyF4: #F4F4F4;
  --greyD9: #D9D9D9;
  --greyD6: #D6D6D6;
  --grey39: #393939;
  --grey605: #605D5D;
  --black05: #050505;
  --black111: #111111;
  --black34: #343434;
  --black43: #434343;
  --blueECF: #ECF0FF;
  --blue3E5: #3E5196;
  --blue3E5-RGB: 62, 81, 150;
  --blue495: #495A99;
  --blue212: #2128BB;
  --green76: #769538;
  --green89: #899E60;
  --green63: #638423;
  --greenC8: #C8DCA1;
  --greenABB: #ABBF83;
  --green3DB: #3DB93B;
  --redCF: #CF3F3F;
  --redCA5: #CA5D5D;
  --shadow: rgba(112, 112, 112, 0.5);

  /* styles for light theme */
  --background-light: 244, 244, 244;
  --text-color-light: 17, 17, 17;
  --block-background-light: 253, 253, 253;
  --hover-row-light: 236, 240, 255;

  /* styles for dark theme */
  --background-dark: 17, 17, 17;
  --text-color-dark: 244, 244, 244;
  --block-background-dark: 5, 5, 5;
  --hover-row-dark: 73, 90, 153;
}

[data-theme='light'] {
  --background: var(--background-light);
  --text-color: var(--text-color-light);
  --block-background: var(--block-background-light);
  --hover-row: var(--hover-row-light);
}

[data-theme='dark'] {
  --background: var(--background-dark);
  --text-color: var(--text-color-dark);
  --block-background: var(--block-background-dark);
  --hover-row: var(--hover-row-dark);
}

@media (max-width: 1400px) {}

@media (max-width: 1200px) {}

@media (max-width: 992px) {}

@media (max-width: 768px) {}

@media (max-width: 576px) {}


input[type='color'] {
  border-radius: 50%;
}
input[type='color']:focus {
  border-color: red;
}
