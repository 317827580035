.card {
  position: relative;
  justify-content: flex-start;
  gap: 5px;
  width: 170px;
  max-width: 170px;
  height: 44px;
  padding: 5px;
  border: 1px solid rgb(var(--background));
  border-radius: 5px;
  background-color: rgb(var(--background));
  z-index: 66;
}

.title {
  position: relative;
  margin-right: auto;
  white-space: nowrap;
  overflow: hidden;
}

.size {
  position: absolute;
  top: calc(100% - 4px);
  font-size: 10px;
  color: var(--grey78);
}

.btn {
  position: relative;
  z-index: 2;
}
