.resizeSVG svg {
  width: 18px;
}

.withList {
  position: relative;
}

.sublist {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3px;
  width: min-content;
  padding: 10px;
  transform: translateX(calc(-100% - 20px));
}

.sublist::after {
  content: '';
  position: absolute;
  right: -25px;
  top: 0;
  width: 25px;
  height: 50px;
}
