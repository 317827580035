.container {
  max-width: 1430px;
  padding: 0 15px;
  margin: 0 auto;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.page {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.pageContent {
  width: 100%;
  height: calc(100% - 60px);
  padding: 20px;
  border-top-left-radius: 20px;
  background-color: var(--greyF4);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
}

.pageHeader {
  justify-content: space-between;
  gap: 5px;
  min-height: 60px;
  padding: 0 35px 0 10px;
  width: 100%;
}

.contentBlock {
  width: 100%;
  height: max-content;
  padding: 20px 30px;
  border-radius: 10px;
  background-color: rgb(var(--block-background));
}

.blockTitleWrapper {
  width: 100%;
  padding: 15px;
  border-bottom: 1px solid var(--greyF4);
}

.blockHeader {
  padding-bottom: 20px;
  border-bottom: 1px solid var(--greyF4);
}

.blockTitle {
  font-size: 16px;
  font-weight: 500;
}

.uppercase {
  text-transform: uppercase;
}

.icon {
  display: flex;
}

.btn {
  display: inline-block;
  padding: 10px 20px;
  border: 1px solid var(--blue3E5);
  border-radius: 5px;
  background-color: var(--blue3E5);
  color: var(--whiteF8);
  font-size: 16px;
  font-weight: 400;
  transition: .3s;
  cursor: pointer;
}

.btn:hover {
  background-color: var(--blue495);
  border-color: var(--blue495);
}

.btn:active {
  background-color: var(--blue3E5);
  border-color: var(--blue3E5);
}

.btnTransparent {
  padding: 10px 20px;
  color: var(--blue3E5);
  border: 1px solid var(--blue3E5);
  background-color: transparent;
}

.btnTransparent:hover {
  color: var(--blue495);
  border: 1px solid var(--blue495);
  background-color: transparent;
}

.btnTransparent:active {
  color: var(--blue3E5);
  border: 1px solid var(--blue3E5);
  background-color: transparent;
}

.btn:disabled {
  pointer-events: none;
  background-color: var(--grey78);
  border: 1px solid var(--grey78);
}

.btn.progress {
  pointer-events: none;
  background-color: var(--grey78);
}

.iconBtn {
  background: transparent;
  border: unset;
  gap: 10px;
}

.iconBtnGroup {
  gap: 15px;
}

.activeFilter svg path {
  fill: green;
}

.dotsBtn {
  padding: 0 5px;
}

.menuBtn {
  white-space: nowrap;
}

.absoluteLink {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
}

.shadow {
  box-shadow: 0px 4px 11px 0px var(--shadow);
}

.pageShadow {
  box-shadow: inset 0px 4px 11px 0px var(--shadow);
}

.danger {
  color: var(--redCF);
}

.absolutePortal {
  position: absolute;
}

.menuList {
  flex-direction: column;
  gap: 15px;
  padding: 15px;
  border-radius: 10px;
  background-color: rgb(var(--block-background));
  z-index: 5;
}

.imageBox {
  overflow: hidden;
}

.imageBox img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.nonTouch {
  pointer-events: none;
}

.nowrapString {
  white-space: nowrap;
}

.tagsWrapper {
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 5px;
  width: max-content;
  width: 100%;
}

.tag {
  padding: 2px 4px;
  border-radius: 2px;
  font-size: 10px;
  font-weight: 500;
}

.tagWhite {
  color: var(--white);
}

.messageCopy {
  position: fixed;
  top: 20px;
  right: 20px;
  width: 340px;
  height: max-content;
  padding: 20px 10px;
  border-radius: 10px;
  border: 1px solid var(--white);
  background-color: var(--blue3E5);
  text-align: center;
  color: var(--white);
  z-index: 10000;
}

.messageCopy.error {
  background-color: var(--redCF);
}

.react-multi-email {
  background-color: rgb(var(--block-background)) !important;
  border: 1px solid var(--greyD6) !important;
}

.react-multi-email [data-tag] {
  background-color: rgb(var(--hover-row)) !important;
}

@media (max-width: 768px) {
  .contentBlock {
    padding: 20px 20px;
  }

  .pageContent {
    padding: 10px;
  }
}

@media (max-width: 576px) {
  .pageHeader {
    padding: 0 35px 0 0;
  }
}
