.item {
  position: relative;
  height: 100%;
  cursor: pointer;
  width: 100%;
  border-top: 1px solid var(--greyD6);
  border-bottom: 1px solid var(--greyD6);
}

.item:first-child {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-left: 1px solid var(--greyD6);
}

.last {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-right: 1px solid var(--greyD6);
}

.user {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  width: 100%;
  padding: 5px 10px;
  border: 1px solid var(--greyD6);
}

.time {
  gap: 5px;
  font-size: 12px;
}

.dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.triangle {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 8px;
  height: 8px;
  transform: translateY(calc(100% + 2px)) translateX(-50%) rotate(45deg);
  background-color: rgb(var(--block-background));
  z-index: 50;
  border-top: 1px solid var(--greyD6);
  border-left: 1px solid var(--greyD6);
}

