.ql-toolbar.ql-snow {
  display: none;
  width: 100%;
  border: 1px solid var(--greyD6) !important;
  border-radius: 5px;
  background-color: rgb(var(--block-background));
}

.addQuillWrapper .ql-toolbar.ql-snow {
  position: absolute;
  top: 0;
  transform: translateY(calc(-100% - 3px));
  z-index: 40;
}

.editQuillWrapper {
  padding: 0;
}

.quillWrapper.active .ql-toolbar.ql-snow {
  display: block;
}

.addQuillWrapper .ql-editor, .editQuillWrapper .ql-editor {
  background-color: transparent;
  border: 1px solid var(--greyD6);
  border-radius: 5px;
  padding: 10px 105px 10px 15px !important;
}

.ql-editor.ql-blank::before {
  color: var(--greyD6) !important;
}

.editQuillWrapper .ql-editor {
  padding: 10px 15px 10px 15px !important;
}

.ql-editor.ql-blank::before {
  font-style: normal !important;
}

.ql-container.ql-snow {
  border: unset !important;
}
