.taskCard {
  position: relative;
  width: 836px;
  min-width: 836px;
  align-items: flex-start;
}

.top {
  width: 100%;
  align-items: flex-start;
}

.topLeft {
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  min-height: 100%;
  width: 100%;
  padding-right: 20px;
  border-right: 1px solid var(--greyF4);
}

.withoutBorder {
  padding-right: 0;
  border-right: unset;
}

.controls {
  padding-left: 20px;
}

.textWrapper {
  flex-grow: 1;
  width: 100%;
  min-height: 30px;
}

.taskName {
  cursor: pointer;
  white-space: pre-line;
  word-break: break-word;
}

.cardPopup {
  z-index: 900;
}

.bottomBtnWrapper {
  padding-left: 15px;
}

.bottomBtn.active {
  font-weight: 700;
  border-bottom: 1px solid var(--blue3E5);
}

@media (max-width: 1400px) {}

@media (max-width: 1200px) {}

@media (max-width: 900px) {
  .taskCard {
    width: 100%;
    min-width: 100%;
  }
}

@media (max-width: 768px) {
  .topLeft {
    padding-right: 0;
    border-right: unset;
  }

  .controls {
    position: absolute;
    top: 50px;
    right: 20px;
    padding: 20px;
    background-color: rgb(var(--block-background));
    box-shadow: 0px 4px 11px 0px var(--shadow);
    border-radius: 10px;
  }

  .controlBtn {
    position: absolute;
    top: 24px;
    right: 20px;
  }
}

@media (max-width: 576px) {
  .controlBtn {
    top: 20px;
  }
}
