.list {
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding-top: 10px;
}

.grey {
  color: var(--grey605);
  font-size: 12px;
}

.user {
  color: var(--blue3E5);
}
