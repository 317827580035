.taskBody {
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
}

.taskBody.paddingRight {
  width: calc(100% - 45px);
}

.taskUsers {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: max-content;
}

.taskUsers *:not(:first-child) {
  margin-top: -6px;
}

.addUser {
  position: relative;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: var(--greyF4);
}

.addUser svg {
  width: 8px;
  height: 8px;
}

.taskName {
  position: relative;
  flex-grow: 1;
}

.taskName pre {
  font-size: 16px;
  white-space: pre-line;
  word-break: break-word;
}

.editBtn {
  position: absolute;
  top: 0;
  right: 0;
}

.textWrapper {
  flex-grow: 1;
  /* width: 100%; */
  min-height: 60px;
}

.textWrapper * {
  font-size: 16px;
}
