.sitePage {
  min-height: 100%;
  min-width: 100%;
  display: flex;
}

.sidebar {
  width: max-content;
  /* transition: .3s; */
}

.content {
  width: 100%;
  min-height: 100%;
  /* transition: .2s; */
  padding-left: 210px;
}
