.inputWrapper {
  position: relative;
  width: 100%;
}

.input {
  width: 100%;
  height: 38px;
  padding-left: 12px;
  border: 1px solid var(--greyD6);
  border-radius: 5px;
  background: transparent;
}

.inputError {
  border: 1px solid var(--redCF);
}

input:focus {
  outline: none;
}

textarea {
  resize: none;
  word-break: break-word;
}

textarea:focus {
  outline: none;
}

select:focus {
  outline: none;
}

.areaWrapper {
  position: relative;
  height: max-content;
}

.resizeArea {
  position: absolute;
  inset: 0;
  height: 100%;
  padding-top: 5px;
}

.rewidthArea {
  position: relative;
  color: transparent;
  width: max-content;
  min-width: 30px;
}

.rewidthInput {
  position: absolute;
  top: -2px;
  left: -2px;
  padding: 0;
  width: 100%;
}

.hiddenText {
  padding: 4px;
  visibility: hidden;
  white-space: pre-wrap;
  word-break: break-word;
  max-width: 100%;
  width: 100%;
}

.fileInputWrapper {
  position: relative;
  width: max-content;
  height: max-content;
}

.fileInput {
  position: absolute;
  visibility: hidden;
  inset: 0;
}

.fileLabel {
  cursor: pointer;
  margin-right: 15px;
}

.fileLabel span {
  margin-right: 10px;
}

.checkboxContainer {
  padding-left: 25px;
  justify-content: flex-start;
}

.checkboxContainer:not(:last-child) {
  margin-bottom: 14px;
}

.checkboxContainer input {
  position: absolute;
  margin-right: 14px;
  visibility: hidden;
}

.checkboxContainer label {
  cursor: pointer;
  position: relative;
}

label {
  user-select: none;
}

.checkboxContainer label::before {
  content: '';
  position: absolute;
  width: 18px;
  height: 18px;
  border: 1px solid var(--greyD6);
  border-radius: 3px;
  left: -27px;
  box-sizing: border-box;
}

.checkboxContainer input:checked+label::before {
  content: url("../images/icons/checked.svg");
  display: flex;
  justify-content: center;
  align-items: center;
}
