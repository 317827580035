.multiselectWrapper {
  position: relative;
}

.textSelect {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  pointer-events: none;
}

.react-select__clear-indicator {
  padding: 0 !important;
  width: 14px !important;
  height: 14px !important;
}

.react-select__dropdown-indicator {
  padding: 0 !important;
  width: 14px !important;
  height: 14px !important;
}

.react-select__clear-indicator svg, .react-select__dropdown-indicator svg {
  width: 100% !important;
  height: 100% !important;
}

.react-select__indicator-separator {
  display: none !important;
}

.react-select__multi-value {
  display: none !important;
}

.react-select__control {
  border-color: var(--greyD6) !important;
  background: transparent !important;
}

.noBorder .react-select__control {
  border-color: rgb(var(--background)) !important;
  background: rgb(var(--background)) !important;
}

.react-select-container.selectError .react-select__control {
  border-color: var(--redCF);
}

.react-select__menu {
  background-color: rgb(var(--block-background)) !important;
  border: 1px solid var(--greyD6) !important;
  box-shadow: none !important;
}

.react-select__option--is-focused {
  background-color: rgb(var(--hover-row)) !important;
}

.react-select__single-value {
  color: rgb(var(--text-color)) !important;
}

.react-select__option--is-selected {
  color: rgb(var(--text-color)) !important;
}
