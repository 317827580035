div ::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

div ::-webkit-scrollbar-track-piece {
  background-color: var(--greyD6);
  border-radius: 1px;
}

div ::-webkit-scrollbar-thumb:vertical {
  background-color: var(--blue3E5);
  border-radius: 1px;
}

div ::-webkit-scrollbar-thumb:horizontal {
  background-color: var(--blue3E5);
  border-radius: 1px;
}

div {
  scrollbar-color: var(--blue3E5) var(--greyD6);
  /* «цвет ползунка» «цвет полосы скроллбара» */
  scrollbar-width: thin;
  /* толщина */
}

.simplebar-scrollbar::before {
  background: var(--blue3E5) !important;
}

.simplebar-scrollbar:hover .simplebar-scrollbar::before {
  background: var(--blue3E5) !important;
  opacity: 1;
}
