.emojiWrap {
  position: relative;
}

.emojiBox {
  width: 400px;
  height: max-content;
  z-index: 1000;
}

.notPortal {
  position: absolute;
  bottom: calc(100% + 20px);
  right: -20px;
}

@media (max-width: 1400px) {}

@media (max-width: 1200px) {}

@media (max-width: 992px) {}

@media (max-width: 768px) {}

@media (max-width: 576px) {
  .emojiBox {
    width: 300px !important;
  }
}
