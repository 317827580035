.content {
  width: 100%;
  height: 100%;
  padding: 0;
  overflow-x: auto;
  position: relative;
}

.maxHeight {
  max-height: 100vh;
}
