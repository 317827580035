em-emoji-picker {
  --background-rgb: var(--block-background);
  --color-border: rgba(120, 120, 120, 0.3);
  --rgb-accent: var(--blue3E5-RGB);
  --rgb-background: var(--block-background);
  --rgb-color: var(--text-color);
  --shadow: 0px 4px 11px 0px #707070;

  width: 100% !important;
}
