/* .scrollContainer {
  width: 100%;
  height: 100%;
  overflow-x: auto;
} */

.board {
  display: inline-flex;
  /* justify-content: flex-start;
  align-items: flex-start; */
  gap: 25px;
  width: max-content;
  max-height: 100%;
  height: 100%;
}

.addBtn {
  justify-content: center;
  align-items: center;
  min-width: 29px;
  height: 29px;
  margin-top: 17px;
  border-radius: 4px;
  border: none;
  background-color: rgb(var(--block-background));
  transition: .3s;
}

.btnActive {
  transform: rotate(45deg);
  border-radius: 50%;
}

@media (max-width: 1400px) {}

@media (max-width: 1200px) {}

@media (max-width: 992px) {}

@media (max-width: 768px) {
  .board {
    gap: 10px;
  }
}

@media (max-width: 576px) {}
