.projectHeader {
  justify-content: flex-start;
  flex: 1 1 0;
  overflow-x: hidden;
  height: 100%;
}

.pageTitle {
  padding-right: 10px;
  width: max-content;
  font-weight: 500;
}

.boardsLine {
  justify-content: flex-start;
  flex: 1 1 0;
  height: 100%;
  overflow-x: auto;
  padding-left: 10px;
}

.titleIcon {
  margin-right: 15px;
}

.boardWrapper {
  align-self: stretch;
  align-items: flex-start;
  padding-top: 10px;
}

.emptyBoards {
  margin-right: 20px;
}

.addBtn {
  padding-left: 20px;
}

.addBoardWrapper {
  position: relative;
  padding-right: 15px;
  padding-left: 15px;
}

.submitBoardBtn {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  padding: 2px 7px;
}

.addBoardInput {
  width: 170px;
  padding-left: 6px;
  padding-right: 50px;
}

.inputActiveBtb {
  transform: rotate(45deg);
  border-radius: 50%;
  background: var(--greyD6);
  padding: 5px;
}

.acrhBox {
  position: relative;
  padding-left: 20px;
}

.acrhBtn {
  color: var(--grey605);
}

.archivePortal {
  transform: translateX(-100%);
}

.scrollBtns {
  margin-left: 10px;
  gap: 5px;
}

.arrowBtn {
  justify-content: center;
  width: 25px;
  height: 25px;
  border: 1px solid var(--blue3E5);
  border-radius: 50%;
  background: transparent;
  color: var(--blue3E5);
  font-size: 18px;
}

@media (max-width: 1400px) {}

@media (max-width: 1200px) {}

@media (max-width: 992px) {}

@media (max-width: 768px) {
  .pageTitle {
    margin-right: 0;
  }

  .addBoardInput {
    width: 200px;
    padding-left: 6px;
    padding-right: 50px;
  }
}

@media (max-width: 576px) {
  .titleIcon {
    margin-right: 0;
  }
}
