.subtask {
  position: relative;
  padding: 15px 15px 20px 10px;
  background-color: rgb(var(--block-background));
}

.subtask::before {
  content: '';
  position: absolute;
  top: -12px;
  left: 0;
  width: 34px;
  height: 38px;
  border-bottom: 1px solid;
  border-left: 1px solid;
  border-color: var(--blue3E5);
  border-bottom-left-radius: 10px;
  transform: translateX(-100%);
}

.notPseudo.subtask::before {
  display: none;
}

.subTaskCheckContainer {
  position: relative;
  padding-right: 25px;
  min-height: 51px;
}

.subTaskBtnGroup {
  position: absolute;
  top: 0;
  right: 0;
  flex-direction: column;
}

.shadow {
  position: relative;
  background-color: var(--blue495);
  border-radius: 10px;
  border: 1px solid var(--blue3E5);
  opacity: .5;
}

.shadow div {
  opacity: 0;
}

.subtask.shadow::before {
  left: -1px;
}
