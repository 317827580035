.name {
  margin-right: 10px;
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
}

.popupWrapper {
  position: absolute;
  top: calc(100%);
  left: 0;
  padding-top: 10px;
  z-index: 200;
}

.userPopup {
  width: max-content;
  min-width: 150px;
  padding: 10px 15px 15px 10px;
}

.userData {
  justify-content: flex-start;
  padding: 0 10px 20px 10px;
}

.userInfo {
  align-self: stretch;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.userFullName {
  justify-content: flex-start;
}

.popupBtnBox:not(:first-child) {
  border-top: 1px solid var(--greyE1);
}

.cabinetBtnBox {
  padding: 0 10px 10px 10px;
}

.exitBtnBox {
  padding: 10px 10px 0 10px;
}

.popupBtn {
  border: unset;
  background-color: transparent;
  justify-content: flex-start;
}

.popupBtn svg {
  width: 18px;
  height: 18px;
  margin-right: 10px;
  pointer-events: none;
}

.popupBtn span {
  font-size: 14px;
  transition: .3s;
  pointer-events: none;
}

.popupBtn:hover span {
  color: var(--blue3E5);
}

.popupBtn:active span {
  color: var(--black05);
}

@media (max-width: 1400px) {}

@media (max-width: 1200px) {}

@media (max-width: 992px) {}

@media (max-width: 768px) {}

@media (max-width: 576px) {}
