.popup {
  position: fixed;
  top: 0;
  left: 0;
  justify-content: center;
  padding: 20px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(62, 81, 150, .3);
  z-index: 1000;
  overflow: hidden;
}

.popupWrapper {
  display: flex;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.popupWindow {
  position: relative;
  margin: auto;
  align-self: center;
  background-color: rgb(var(--block-background));
  border-radius: 10px;
  padding: 20px;
  max-width: max-content;
  min-width: 510px;
  height: max-content;
}

.closeBtn {
  position: absolute;
  top: -30px;
  right: -30px;
}

.popupHeader {
  padding: 0 0 15px 10px;
  font-size: 16px;
  font-weight: 500;
}

.popupform {
  flex-direction: column;
  align-items: flex-start;
}

.popupInputGroup {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  gap: 14px;
}

.popupInputAllView {
  width: 100%;
}

.popupInputBox {
  position: relative;
  justify-content: flex-start;
  margin-bottom: 18px;
  width: 100%;
  gap: 10px;
}

.addBtnWrapper {
  justify-content: flex-end;
}

.popupTextarea {
  padding-top: 12px;
  resize: none;
  height: 70px;
}

.errorMessage {
  position: absolute;
  bottom: -13px;
  left: 10px;
  color: var(--redCF);
  font-size: 10px;
}

.popupBtnsWrapper {
  align-self: flex-end;
}

.popupBtnsWrapper .btnTransparent {
  margin-right: 20px;
}

.popupLabel {
  width: 31%;
  min-width: 31%;
  font-size: 12px;
}

.topLabel {
  align-self: flex-start;
}

.filter .topLabel {
  padding-top: 0;
}

.errorForm {
  color: var(--redCF);
  margin-top: 10px;
  max-width: 100%;
}

@media (max-width: 992px) {
  .popup {
    padding: 15px;
  }
}

@media (max-width: 768px) {

  .popupLabel {
    min-width: auto;
    width: max-content;
  }

  .popupInputBox {
    flex-direction: column;
    align-items: flex-start;
  }

  .popupInputBox .popupLabel~.popupErrorMessage {
    left: 10px;
  }

  .popupWindow {
    min-width: 100%;
  }

  .popupInputBox {
    width: 100%;
  }

  .closeBtn {
    top: 15px;
    right: 15px;
  }

  .closeBtn svg path {
    stroke: var(--blue3E5);
  }
}

@media (max-width: 576px) {
  .popupWindow {
    padding: 15px;
  }
}

@media (max-width: 350px) {
  .popupBtnsWrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-self: flex-end;
  }

  .popupBtnsWrapper .btnTransparent {
    margin-right: 0;
  }
}
