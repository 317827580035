.workTime {
  background-color: rgba(61, 185, 59, .3);
}

.today {
  background-color: rgba(var(--hover-row), .5) !important;
}

.weekend {
  background-color: rgba(207, 63, 63, .2);
}

.rbc-time-gutter .rbc-time-slot {
  background-color: unset;
}

.rbc-day-slot .rbc-time-slot {
  border-top: 1px solid rgba(var(--background), .2) !important;
}

.rbc-time-view {
  min-width: 600px;
}

.rbc-toolbar {
  min-width: 600px;
}

.rbc-calendar {
  overflow-y: auto;
}

.rbc-current-time-indicator {
  background-color: var(--redCF) !important;
  height: 2px !important;
}

.rbc-current-time-indicator::before {
  content: '';
  position: absolute;
  width: 12px;
  height: 12px;
  left: -6px;
  top: -5px;
  background-color: inherit;
  border-radius: 50%;
}

.rbc-toolbar-label::first-letter {
  text-transform: uppercase;
}

.rbc-btn-group button {
  color: rgb(var(--text-color)) !important;
  transition: .3s;
}

.rbc-btn-group button:hover {
  background-color: rgb(var(--hover-row)) !important;
}
.rbc-btn-group button:focus {
  background-color: rgb(var(--hover-row)) !important;
}
.rbc-btn-group button.rbc-active {
  background-color: var(--blue3E5) !important;
}

