.list {
  position: absolute;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 5px;
  width: max-content;
  max-width: 320px;
  top: calc(100% + 5px);
  left: 0;
  padding: 6px;
  border-radius: 5px;
  z-index: 10;
}
