.taskWrap {
  width: 100%;
  overflow: visible;
}

.task {
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
  padding: 15px 15px 15px 10px;
  cursor: pointer !important;
  min-width: 100%;
  transition: .3s;
  background-color: rgb(var(--block-background));
}

.task.paddingTop {
  padding: 45px 15px 15px 10px;
}

.shadow {
  position: relative;
  background-color: var(--blue495);
  border-radius: 10px;
  border: 1px solid var(--blue3E5);
  opacity: .5;
}

.shadow div {
  opacity: 0;
}

.taskDrag {
  background-color: var(--blueECF) !important;
}

.subList {
  flex-direction: column;
  gap: 12px;
  padding-left: 44px;
  padding-top: 12px;
  width: 100%;
}

.subtask {
  position: relative;
  width: 100%;
}

.subtask:not(:last-child)::after {
  content: '';
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: -35px;
  border-left: 1px solid var(--blue3E5);
}

.subtask.notPseudo:not(:last-child)::after {
  display: none;
}

.targetTask {
  border: 2px dashed var(--blue3E5);
}

.controls {
  position: absolute;
  top: 15px;
  right: 15px;
}
