.timer {
  position: fixed;
  bottom: 0;
  left: 20px;
  max-width: 255px;
  padding: 10px 15px;
  border-radius: 10px;
  background-color: rgb(var(--block-background));
  transform-origin: top left;
  transform: translateX(0) rotate(-90deg);
  transition: .5s;
  z-index: 300;
}

.fullBorder {
  min-width: 250px;
  border-radius: 10px 10px 10px 0;
  left: 100%;
  bottom: 20px;
  transform: translateX(calc(-100% - 80px)) rotate(0);
}

.hide {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 5px;
  border-radius: 5px 0 0 5px;
  border-right: 1px solid rgb(var(--block-background));
  background-color: rgb(var(--block-background));
  transform: translateX(-100%) translateY(1px);
}

@media (max-width: 768px) {
  .timer {
    left: 2px;
    padding: 6px 15px;
  }

  .fullBorder {
    border-radius: 10px 10px 10px 0;
    left: 100%;
    bottom: 10px;
    transform: translateX(calc(-100% - 80px)) rotate(0);
  }
}

@media (max-width: 576px) {
  .fullBorder {
    min-width: 230px;
    transform: translateX(calc(-100% - 60px)) rotate(0);
  }
}
