.react-datepicker-wrapper {
  position: relative;
  width: 100%;
}

.react-datepicker-wrapper svg {
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
}

.pickerDate {
  width: 100%;
  height: 38px;
  padding-left: 12px;
  border: 1px solid var(--greyD6);
  border-radius: 5px;
  background: transparent;
}

.pickerError {
  border: 1px solid var(--redCF);
}

.react-datepicker {
  border: unset !important;
  background-color: rgb(var(--block-background)) !important;
  box-shadow: 0px 4px 11px 0px var(--shadow);
  border-radius: 8px !important;
}

.react-datepicker__navigation {
  width: 16px !important;
  height: 16px !important;
}

.react-datepicker__navigation {
  background: url("../images/icons/calendarArrow.svg") !important;
  background-size: contain !important;
  top: 8px !important;
}

.react-datepicker__navigation--previous {
  left: 25px !important;
}

.react-datepicker__navigation--next {
  right: 25px !important;
  transform: scale(-1, 1);
}

.react-datepicker__navigation span {
  display: none;
}

.react-datepicker__day-name {
  color: rgba(var(--text-color), .75) !important;
}

.react-datepicker__day-name[aria-label="суббота"],
.react-datepicker__day-name[aria-label="воскресенье"] {
  color: var(--redCA5) !important;
}

.react-datepicker__day {
  color: rgb(var(--text-color)) !important;
}

.react-datepicker__day:hover {
  background-color: rgb(var(--hover-row)) !important;
  color: rgb(var(--text-color)) !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: rgba(var(--hover-row), .7) !important;
}

.react-datepicker__header {
  background-color: unset !important;
  border-bottom: unset !important;
  padding: 8px 0 0 0 !important;
}

.react-datepicker__current-month::first-letter {
  text-transform: uppercase;
}

.react-datepicker__current-month {
  color: rgb(var(--text-color)) !important;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__day--outside-month {
  color: var(--grey605) !important;
}

.react-datepicker__day--in-range {
  background-color: unset !important;
  color: var(--blue3E5) !important;
}

.react-datepicker__day--range-start, .react-datepicker__day--range-end {
  background-color: var(--blue3E5) !important;
  color: var(--white) !important;
}

.react-datepicker__day--selected {
  color: var(--white) !important;
}
