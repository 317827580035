.addTaskForm {
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
  width: 100%;
  padding: 10px;
  margin-top: 12px;
  background-color: rgba(var(--block-background), .9);
}

.textWrapper {
  width: 100%;
  min-height: 60px;
}

.smallbtn {
  font-size: 10px;
  padding: 8px 20px;
}
