.wrapper {
  width: 100%;
}

.timeline {
  position: relative;
  width: 100%;
  height: 15px;
  background-color: transparent;
  border-radius: 20px;
}


.text {
  font-size: 12px;
}

.total {
  text-align: right;
}
