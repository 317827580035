.smallCalendar .fc {
  width: 100%;
}

.smallCalendar .fc-theme-standard .fc-scrollgrid, .smallCalendar .fc-theme-standard th, .smallCalendar .fc-theme-standard td {
  border: unset !important;
}

.smallCalendar .fc-scroller {
  overflow: visible !important;
}

.smallCalendar .fc-daygrid-day-events {
  position: absolute !important;
  top: 0 !important;
  left: 50% !important;
  width: 6px !important;
  height: 6px !important;
  transform: translateX(-50%);
}

.smallCalendar .fc-daygrid-event-harness {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 6px !important;
  height: 6px !important;
  border-radius: 50% !important;
  overflow: hidden;
}

.smallCalendar .fc-daygrid-event-harness * {
  width: 100% !important;
  height: 100% !important;
}

.smallCalendar .fc-event {
  margin: 0 !important;
}

.smallCalendar .fc-event-title {
  visibility: hidden;
}

.smallCalendar .fc-daygrid-day-top {
  position: absolute;
  inset: 0;
  justify-content: center;
  align-items: center;
}

.smallCalendar .fc-daygrid-day-top a {
  font-size: 16px;
}

.smallCalendar .fc-day-today {
  background-color: transparent !important;
}

.smallCalendar .fc-day-today .fc-daygrid-day-frame {
  background-color: rgb(var(--hover-row));
  border-radius: 20px;
}

.smallCalendar .fc-day-today .fc-daygrid-day-frame .fc-daygrid-day-number {
  font-weight: 700;
}

.smallCalendar .fc-header-toolbar {
  position: relative;
  padding-top: 80px;
}

.smallCalendar .fc-today-button {
  position: absolute;
  left: 0;
  top: 0;
  background-color: var(--blue3E5) !important;
  border-color: var(--blue3E5) !important;
}

.smallCalendar .fc-toolbar-title {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  font-size: 18px !important;
}

.smallCalendar .fc-toolbar-title::first-letter {
  text-transform: uppercase;
}

.smallCalendar .fc-button-group {
  margin-left: 0 !important;
  position: absolute !important;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.smallCalendar .fc-prev-button, .smallCalendar .fc-next-button {
  width: 24px !important;
  height: 24px !important;
  padding: 0 !important;
  border-radius: 50% !important;
  flex: none !important;
  background-color: transparent !important;
  border: unset !important;
  text-align: center;
  background: url("../images/icons/calendarArrow.svg") !important;
  background-size: contain !important;
}

.smallCalendar .fc-next-button {
  transform: rotate(180deg);
}

.smallCalendar .fc-prev-button span, .smallCalendar .fc-next-button span {
  display: none;
}
