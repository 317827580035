.popup-enter {
  opacity: 0;
}
.popup-enter-active {
  opacity: 1;
  transition: opacity .3s;
}
.popup-exit {
  opacity: 1;
}
.popup-exit-active {
  opacity: 0;
  transition: opacity .3s;
}
