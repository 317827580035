.taskControls {
  position: relative;
  gap: 5px;
}

.timer {
  position: absolute;
  font-size: 9px;
  padding: 0;
  width: max-content;
  right: 0;
  transform: translateY(calc(-100% - 3px));
}

.timer.active::after {
  content: '';
  position: absolute;
  right: -8px;
  top: 5px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: rgba(42, 204, 42, 0.822);
}

.delBtn {
  position: absolute;
  padding-right: 10px;
  top: 50%;
  left: 0;
  transform: translateY(-50%) translateX(calc(-100% + 5px));
}
