.table {
  display: table;
  width: 100%;
  border-collapse: collapse;
}

.tableRow {
  position: relative;
  display: table-row;
}

.tableRowHov:hover {
  background-color: rgb(var(--hover-row));
}

.tableCell {
  position: relative;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  padding: 15px 5px;
}

.emptyRow {
  position: absolute;
  left: 0;
}

.tableCell:first-child {
  border-radius: 5px 0 0 5px;
}

.tableCell:last-child {
  border-radius: 0 5px 5px 0;
}

.leftCell {
  text-align: left;
}

.rightCell {
  text-align: right;
}

.tableHeader {
  display: table-header-group;
  border-bottom: 1px solid var(--greyF4);
}

.tableBody {
  display: table-row-group;
}
