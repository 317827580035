.navigation {
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: max-content;
  min-width: 0;
  gap: 20px;
  /* transition: .3s; */
}

.navigation.fullNav {
  align-items: flex-start;
  min-width: max-content;
}

.navItem {
  position: relative;
  width: 100%;
}

.navItem:not(:last-of-type)::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: -5px;
  right: -5px;
  height: 1px;
  background: var(--greyE7);
  opacity: 1;
  /* transition: .3s; */
}

.navLink {
  width: 100%;
  justify-content: center;
}

.navLink.fullNav {
  justify-content: flex-start;
}

.navTitle {
  padding-left: 0;
  transform-origin: left;
  transform: scaleX(0);
  width: 0;
  /* transition: .4s; */
}

.navTitle.fullNav {
  width: auto;
  padding-left: 14px;
  transform: scaleX(1);
}

.projectsList {
  flex-direction: column;
  align-items: flex-start;
  width: 0;
  height: 0;
  padding: 0;
  transform-origin: top left;
  transform: scaleX(0) scaleY(0);
  /* transition: .4s; */
}

.projectsList.fullNav {
  padding: 14px 0 6px 24px;
  width: auto;
  height: max-content;
  transform: scaleX(1) scaleY(1);
}

.scrollList {
  max-height: 180px;
  overflow-y: auto;
}

.projectLink {
  width: 100%;
  padding: 7px 10px;
  border-radius: 5px;
  transition: .3s;
}

.projectLink:hover {
  color: var(--black43);
}

.projectLink.active {
  color: rgb(var(--text-color));
  background-color: rgb(var(--hover-row));
}

.openMenuBtn {
  position: absolute;
  top: 60px;
  bottom: 0;
  right: 0;
  width: 30px;
  justify-content: center;
  /* transition: .2s; */
}

.openMenuBtn span {
  transform: rotate(180deg);
  transition: .2s;
}

.opened span {
  transform: rotate(0);
}

.openMenuBtn:hover {
  background-color: rgba(236, 240, 255, .2);
}

.projectsPopup {
  position: absolute;
  left: calc(100% + 20px);
  top: 0;
  width: 200px;
  height: max-content;
  z-index: 200;
}

.projectsPopup::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 100%;
  transform: translateX(-100%);
}

@media (max-width: 1400px) {}

@media (max-width: 1200px) {}

@media (max-width: 1024px) {}

@media (max-width: 992px) {}

@media (max-width: 768px) {
  .notFullItem.navItem:not(:last-of-type)::after {
    opacity: 0;
  }
}

@media (max-width: 576px) {}
