.item {
  position: relative;
  display: block;
  padding: 5px;
  border-radius: 5px;
  background-color: transparent;
}

.item:hover {
  background-color: rgb(var(--hover-row));
}

.name {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;

  margin-bottom: 6px;
  padding-right: 90px;
}

.path {
  color: var(--grey39);
  font-size: 10px;
  font-weight: 400;
}

.btns {
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  top: 5px;
  right: 5px;
  gap: 3px;
}

.comments {
  color: var(--blue3E5);
  font-size: 10px;
  gap: 4px;
}

.timer {
  position: relative;
  font-size: 10px;
  padding: 1px 2px;
  border: 1px solid var(--blue3E5);
  border-radius: 3px;
}

.timer.active {
  background-color: rgba(42, 204, 42, 0.822);
}

.delBtn {
  position: absolute;
  padding-right: 10px;
  top: 50%;
  left: 0;
  transform: translateY(-50%) translateX(calc(-100% + 5px));
}
